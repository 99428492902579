    import { dispatch } from 'd3'
    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            quests: [],
            news: null,
            loadingQuests: false,
        }
    }

    const state = getDefaultState()

    const getters = {
        news: state => {
            return state.news
        },
        quests: state => {
            return state.quests
        },
        loadingQuests: state => {
            return state.loadingQuests
        },
    }

    const mutations = {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        quests: (state, payload) => {
            state.quests = payload
        },
        news: (state, payload) => {
            state.news = payload
        },
        loadingQuests: (state, payload) => {
            state.loadingQuests = payload
        }
    }

    const actions = {

        getQuests: ({ commit }) => {
            commit('loadingQuests', true)
            axiosAuth.get('/quests', {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingQuests', false)
                        if (!res.data.message) {
                            commit('quests', res.data.data)
                            dispatch('getQuestsByID', res.data._id)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingQuests', false)

                    }
                )
        },

        updateQuest: ({ commit }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            commit('updating', true)
            axiosAuth.patch('/quests/admin/', data)
                .then(
                    () => {
                        commit('updating', false)

                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        },

        deleteQuest: ({ commit }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            commit('updating', true)
            axiosAuth.delete('/quests/admin/' + data._id)
                .then(
                    () => {
                        commit('updating', false)

                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }