import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        events: [],
        tempParticipants: [],
        event: null,
        eventComments: [],
        newsComments: [],
        eventEntries: [],
        loadingEvents: false,
        codeValid: null,
        codeUsed: null,
        codeMessage: '',
        activateEvent: null,
        ticketData: null,
        ticketStatus: null,
        registerOk: null,
        activationOk: null,
        emailResended: null
    }
}

const state = getDefaultState()

const getters = {
    tempParticipants: state => {
        return state.tempParticipants
    },
    events: state => {
        return state.events
    },
    event: state => {
        return state.event
    },
    eventComments: state => {
        return state.eventComments
    },
    newsComments: state => {
        return state.eventComments
    },
    eventEntries: state => {
        return state.eventEntries
    },
    loadingEvents: state => {
        return state.loadingEvents
    },
    codeValid: state => {
        return state.codeValid
    },
    codeMessage: state => {
        return state.codeMessage
    },
    codeUsed: state => {
        return state.codeUsed
    },
    activateEvent: state => {
        return state.activateEvent
    },
    ticketData: state => {
        return state.ticketData
    },
    ticketStatus: state => {
        return state.ticketStatus
    },
    registerOk: state => {
        return state.registerOk
    },
    activationOk: state => {
        return state.activationOk
    },
    emailResended: state => {
        return state.emailResended
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    tempParticipants: (state, payload) => {
        state.tempParticipants = payload
    },
    events: (state, payload) => {
        state.events = payload
    },
    event: (state, payload) => {
        state.event = payload
    },
    eventComments: (state, payload) => {
        state.eventComments = payload
    },
    newsComments: (state, payload) => {
        state.eventComments = payload
    },
    eventEntries: (state, payload) => {
        state.eventEntries = payload
    },
    loadingEvents: (state, payload) => {
        state.loadingEvents = payload
    },
    codeValid: (state, payload) => {
        state.codeValid = payload
    },
    codeMessage: (state, payload) => {
        state.codeMessage = payload
    },
    codeUsed: (state, payload) => {
        state.codeUsed = payload
    },
    activateEvent: (state, payload) => {
        state.activateEvent = payload
    },
    ticketData: (state, payload) => {
        state.ticketData = payload
    },
    ticketStatus: (state, payload) => {
        state.ticketStatus = payload
    },
    registerOk: (state, payload) => {
        state.registerOk = payload
    },
    activationOk: (state, payload) => {
        state.activationOk = payload
    },
    emailResended: (state, payload) => {
        state.emailResended = payload
    }
}

const actions = {

    getEventByID: ({ commit, dispatch }, id) => {
        commit('loadingEvents', true)
        axiosAuth.get('/events/byId/' + id, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('loadingEventNews', false)
                    if (!res.data.message) {
                        dispatch('getEventComments', res.data._id)
                        commit('event', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingEvents', false)

                }
            )
    },

    getEvents: ({ commit }) => {
        commit('updating', true)
        axiosAuth.get('/events', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('events', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    getEventComments: ({ commit }, id) => {
        console.log('eventid:', id)
        commit('updating', true)
        axiosAuth.get('/eventComments/' + id)
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('eventComments', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    getNewsComments: ({ commit }, id) => {
        commit('updating', true)
        axiosAuth.get('/newsComments/' + id)
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('newsComments', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    getEventEntries: ({ commit }) => {
        commit('updating', true)
        axiosAuth.get('/events/entries', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('eventEntries', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    getEventEntriesUser: ({ commit }) => {
        commit('updating', true)
        axiosAuth.get('/events/entries/user', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('eventEntries', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    getEventEntriesAdmin: ({ commit }) => {
        commit('updating', true)
        axiosAuth.get('/events/entries', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('eventEntries', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    getEventsAdmin: ({ commit }) => {
        commit('updating', true)
        axiosAuth.get('/events/admin/asd', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('events', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    checkTicket: ({ commit }, payload) => {
        commit('updating', true)
        let data = null
        axiosAuth.get('/events/checkticket/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    commit('ticketData', res.data.data)
                    commit('ticketStatus', res.data.message)
                    data = res.data.data
                }
            )
            .catch(
                error => {
                    console.log(error)
                    commit('updating', false)
                    commit('ticketData', null)
                    commit('ticketStatus', error.response.data.message)
                }
            )
        return data
    },

    scanTicket: ({ commit }, payload) => {
        commit('updating', true)
        axiosAuth.get('/events/scanticket/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    commit('ticketData', res.data.data)
                    commit('ticketStatus', res.data.message)
                }
            )
            .catch(
                error => {
                    console.log(error)
                    commit('updating', false)
                    commit('ticketData', null)
                    commit('ticketStatus', error.response.data.message)
                }
            )
    },

    registerForEvent: ({ commit }, payload) => {
        commit('updating', true)
        axiosAuth.post('/events/registerApp', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    console.log(res)
                    commit('updating', false)
                    commit('paymentState', 'approved')
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    resendEmail: ({ commit }, payload) => {
        commit('updating', true)
        commit('emailResended', null)
        axiosAuth.post('/events/resendemail', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    commit('emailResended', res.data.message)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('emailResended', null)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    downloadInvoice: ({ commit }, payload) => {
        if (!payload._id) return;
        commit('updating', true)

        axiosAuth.get('/invoices/download/' + payload._id, { responseType: 'blob' })
            .then(
                res => {
                    console.log(res)
                    commit('updating', false)

                    var fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', payload.invoice_nr + '.pdf');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                }
            )
            .catch(
                error => {
                    console.log(error)
                    commit('updating', false)
                }
            )
    },

    activateEntry: ({ commit }, payload) => {
        commit('updating', true)
        axiosAuth.post('/events/activateentry', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    commit('activationOk', res.data.status)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    verifyToken: ({ commit }, payload) => {
        commit('updating', true)
        axiosAuth.get('/events/verifytoken/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    commit('activateEvent', res.data)
                }
            )
            .catch(
                error => {
                    commit('updating', false)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    console.log(error.response)
                }
            )
    },

    checkCode: ({ commit }, payload) => {
        commit('updating', true)
        axiosAuth.get('/events/checkcode/' + payload.eventId + '/' + payload.eventCode, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('codeValid', res.data.valid)
                    commit('codeUsed', res.data.used)
                    commit('codeMessage', res.data.message)
                    commit('updating', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('codeValid', false)
                    commit('codeUsed', null)
                    commit('codeMessage', 'Abfragefehler!')
                    commit('updating', false)

                }
            )
    },

    updateEvent: ({ commit, }, payload) => {
        // let data = JSON.parse(JSON.stringify(payload))
        // data.edit = false
        console.log(payload)
        commit('updating', true)
        axiosAuth.patch('/events/' + payload._id, payload)
            .then(
                () => {
                    commit('updating', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)
                }
            )
    },

    updateEventEntry: ({ commit, dispatch }, payload) => {
        let data = JSON.parse(JSON.stringify(payload))
        data.edit = false
        commit('updating', true)
        axiosAuth.patch('/events/entry/' + data._id, data)
            .then(
                () => {
                    dispatch('getEventEntriesAdmin')
                    commit('updating', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)
                }
            )
    },

    downloadSponsorCSV: ({ commit }, payload) => {
        commit('updating', true)
        axiosAuth.get('/events/csv/' + payload.event._id + '/' + payload.sponsor._id)
            .then(
                res => {
                    commit('updating', false)
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', payload.sponsor.title + '.csv');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)
                }
            )
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}