    import { dispatch } from 'd3'
    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            feedComments: [],
            news: null,
            loadingFeedComments: false,
        }
    }

    const state = getDefaultState()

    const getters = {
        news: state => {
            return state.news
        },
        feedComments: state => {
            return state.feedComments
        },
        loadingFeedComments: state => {
            return state.loadingFeedComments
        },
    }

    const mutations = {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        feedComments: (state, payload) => {
            state.feedComments = payload
        },
        news: (state, payload) => {
            state.news = payload
        },
        loadingFeedComments: (state, payload) => {
            state.loadingFeedComments = payload
        }
    }

    const actions = {

        getFeedComments: ({ commit }) => {
            commit('loadingFeedComments', true)
            axiosAuth.get('/feedComments/admin/get', {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingFeedComments', false)
                        if (!res.data.message) {
                            commit('feedComments', res.data)
                            dispatch('getFeedCommentsByID', res.data._id)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingFeedComments', false)

                    }
                )
        },

        updateFeedComments: ({ commit }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            data.edit = false
            commit('updating', true)
            axiosAuth.patch('/feedComments/' + data._id, data)
                .then(
                    () => {
                        commit('updating', false)

                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        },

        deleteFeedComments: ({ commit, dispatch }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            commit('updating', true)
            axiosAuth.delete('/feedComments/' + data)
                .then(
                    () => {
                        commit('updating', false)
                        dispatch('getReports')
                        dispatch('getFeedComments')
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }