    import { dispatch } from 'd3'
    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            reports: [],
            news: null,
            loadingReports: false,
        }
    }

    const state = getDefaultState()

    const getters = {
        news: state => {
            return state.news
        },
        reports: state => {
            return state.reports
        },
        loadingReports: state => {
            return state.loadingReports
        },
    }

    const mutations = {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        reports: (state, payload) => {
            state.reports = payload
        },
        news: (state, payload) => {
            state.news = payload
        },
        loadingReports: (state, payload) => {
            state.loadingReports = payload
        }
    }

    const actions = {

        getReports: ({ commit }) => {
            commit('loadingReports', true)
            axiosAuth.get('/reports', {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        console.log(res)
                        commit('loadingReports', false)
                        if (!res.data.message) {
                            commit('reports', res.data.data)
                            dispatch('getReportsByID', res.data._id)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingReports', false)

                    }
                )
        },

        updateReport: ({ commit, dispatch }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            commit('updating', true)
            axiosAuth.patch('/reports/' + payload, { status: 'bearbeitet' })
                .then(
                    () => {
                        commit('updating', false)
                        dispatch('getReports')
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        },

        deleteReport: ({ commit, dispatch }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            commit('updating', true)
            axiosAuth.delete('/reports/' + data)
                .then(
                    () => {
                        commit('updating', false)
                        dispatch('getReports')
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }