import { dispatch } from 'd3'
import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        users: [],
        news: null,
        loadingUsers: false,
    }
}

const state = getDefaultState()

const getters = {
    news: state => {
        return state.news
    },
    users: state => {
        return state.users
    },
    loadingUsers: state => {
        return state.loadingUsers
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    users: (state, payload) => {
        state.users = payload
    },
    news: (state, payload) => {
        state.news = payload
    },
    loadingUsers: (state, payload) => {
        state.loadingUsers = payload
    }
}

const actions = {

    getUsers: ({ commit }) => {
        commit('loadingUsers', true)
        axiosAuth.get('/admin/users?$limit=1000', {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingUsers', false)
                    if (!res.data.message) {
                        commit('users', res.data)
                        dispatch('getUsersByID', res.data._id)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingUsers', false)

                }
            )
    },

    updateUser: ({ commit }, payload) => {
        let data = JSON.parse(JSON.stringify(payload))
        data.edit = false
        commit('updating', true)
        axiosAuth.patch('/admin/users/' + data.id, data)
            .then(
                () => {
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)
                }
            )
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}