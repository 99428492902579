/*
=========================================================
* Vuetify Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/vuetify-material-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import { store } from '@/store'
import Vuex from 'vuex'
import LoadingOverlay from '@/shared/animations/loadingOverlay.vue'
Vue.component('loading-overlay', LoadingOverlay)
import coreData from "@/mixins/coreDataMixin";

Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);
Vue.use(Vuex);
// plugin setup
Vue.use(DashboardPlugin);

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAWeYE9JS68ozo7WUbIDs5PJFk1CtuUqQs',
        // libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
})

const moment = require('moment')
require('moment/locale/de')

Vue.use(require('vue-moment'), {
    moment
})

router.beforeEach((to, from, next) => {
    console.log(to.path)
        // return next()
    if ((!localStorage.getItem('token') || !localStorage.getItem('admin')) && to.path !== `/admin/login`) {
        console.log('route to login')
        return next(`/admin/login`)
    } else {
        return next()
    }

})

new Vue({
    mixins: [coreData],
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");