<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    flat
  >
    <v-container>
      <v-row>
        <v-col cols="3" class="d-flex align-center">
          <v-menu open-on-hover offset-y bottom min-width="360" max-width="600">
            <template v-slot:activator="{}">
              <v-btn
                :ripple="false"
                color="transparent"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                class="btn-hover text-white font-weight-light ls-0 align-center"
                depressed
                link
                to="/"
              >
                <p
                  class="
                    font-weight-bolder
                    ms-lg-0 ms-4
                    mb-0
                    text-white text-sm
                  "
                >
                  Oelsnitz-ticket.de
                </p>
              </v-btn>
            </template>
          </v-menu>
        </v-col>

        <v-col cols="6" class="mx-auto text-center">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-menu
              v-for="item in links"
              :key="item.name"
              open-on-hover
              offset-y
              bottom
              min-width="360"
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="item.sub ? on : null"
                  :ripple="false"
                  color="transparent"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="
                    text-white
                    font-weight-light
                    text-capitalize
                    ls-0
                    align-center
                  "
                  depressed
                  link
                  :to="item.link"
                >
                  {{ item.name }}
                  <v-img
                    v-if="item.sub"
                    src="@/assets/img/down-arrow-white.svg"
                    alt="down-arrow"
                    class="arrow ms-1 d-lg-block d-none"
                  />
                </v-btn>
              </template>

              <v-list class="border-radius-xl overflow-hidden">
                <template v-if="item.name == 'Mein Bereich'">
                  <app-bar-pages></app-bar-pages>
                </template>
              </v-list>
            </v-menu>
          </template>
        </v-col>

        <v-col cols="3" class="text-right">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              v-if="userInformations"
              @click="logout"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-success bg-gradient-warning'"
              href="javascript:;"
              small
            >
              Ausloggen
            </v-btn>
            <v-btn
              v-else
              @click="$store.commit('popLogin', true)"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-success bg-gradient-warning'"
              href="javascript:;"
              small
            >
              Einloggen
            </v-btn>
          </template>

          <v-btn
            v-show="$vuetify.breakpoint.mobile"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
            color="transparent"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding">
              <v-card-title
                class="
                  pt-0
                  px-4
                  card-padding
                  text-h6
                  font-weight-bold
                  text-typo
                  justify-space-between
                  border-bottom
                "
              >
                Oelsnitz-Ticket.de

                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="18" class="text-typo">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <div v-for="item in linksMobile" :key="item.name">
                  <v-btn
                    :ripple="false"
                    text
                    class="text-typo text-capitalize ls-0"
                    depressed
                    link
                    @click="dialog = false"
                    :to="item.link"
                  >
                    {{ item.name }}
                    <v-spacer></v-spacer>
                  </v-btn>
                  <div class="ml-5" v-if="item.links">
                    <v-btn
                      v-for="item2 in item.links"
                      :key="item2.name"
                      :ripple="false"
                      text
                      class="text-typo text-capitalize ls-0"
                      depressed
                      link
                      @click="item2.sub ? null : (dialog = false)"
                      :to="item2.link"
                    >
                      {{ item2.name }}
                      <v-spacer></v-spacer>
                    </v-btn>
                  </div>
                </div>
                
                <div class="border-bottom my-7"></div>
                <v-btn
                  v-if="userInformations"
                  @click="logout"
                  elevation="0"
                  color="#fff"
                  class="font-weight-bolder py-4 px-4 ms-2"
                  :class="'btn-success bg-gradient-warning'"
                  href="javascript:;"
                  small
                >
                  Ausloggen
                </v-btn>
                <v-btn
                  v-else
                  @click="$store.commit('popLogin', true)"
                  elevation="0"
                  color="#fff"
                  class="font-weight-bolder py-4 px-4 ms-2"
                  :class="'btn-success bg-gradient-warning'"
                  href="javascript:;"
                  small
                >
                  Einloggen
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="loginDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">Einloggen</span>
        </div>
        <v-card-text class="card-padding">
          <v-container class="pa-0">
            <div class="card-padding">
              <p class="text-danger">
                {{ authMessage }}
              </p>
              <p>
                {{
                  $route.query.message == "notAuthorized"
                    ? "Nicht authorisiert!"
                    : ""
                }}
              </p>
              <v-text-field
                label="E-Mail-Adresse"
                placeholder="E-Mail-Adresse"
                color="#e91e63"
                required
                v-model="form.email"
                class="font-size-input input-style"
                :rules="emailRules"
              ></v-text-field>

              <v-text-field
                label="Passwort"
                placeholder="Passwort"
                color="#e91e63"
                required
                v-model="form.password"
                type="password"
                class="font-size-input input-style"
                :rules="min8"
              ></v-text-field>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="#5e72e4"
                small
                @click="submitForm"
                >Login</v-btn
              >
              <h5
                class="
                  text-h5 text-secondary text-center
                  mt-5
                  font-weight-normal
                "
              >
                Du hast noch keinen Account?
              </h5>
              <v-row justify="center">
                <div>
                  <v-btn
                    v-if="!userInformations"
                    :elevation="0"
                    @click="$store.commit('popRegister', true)"
                    class="
                      font-weight-bold
                      text-xs
                      btn-default
                      bg-gradient-warning
                      py-5
                      px-6
                      mt-6
                      mb-2
                      me-2
                      ms-auto
                    "
                    color="primary"
                  >
                    Jetzt Registrieren
                  </v-btn>
                </div>
              </v-row>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="$store.commit('popLogin', false)"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-secondary btn-outline-secondary
              shadow-none
              bg-transparent
              py-3
              px-6
            "
            >Abbrechen</v-btn
          >
          <!-- 
          <v-btn
            @click="savePushnotification"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Registrieren</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="registerDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Registrieren</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="pa-0">
            <div class="card-padding">
              <div v-if="!accountCreated">
                <p class="text-danger">
                  {{ authMessage }}
                </p>
                <v-text-field
                  label="Benutzername"
                  placeholder="Benutzername"
                  color="#e91e63"
                  required
                  v-model="form.username"
                  class="font-size-input input-style"
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  label="E-Mail-Adresse"
                  placeholder="E-Mail-Adresse"
                  color="#e91e63"
                  required
                  v-model="form.email"
                  class="font-size-input input-style"
                  :rules="emailRules"
                ></v-text-field>

                <v-text-field
                  label="Passwort"
                  placeholder="Passwort"
                  color="#e91e63"
                  required
                  v-model="form.password"
                  type="password"
                  class="font-size-input input-style"
                  :rules="min8"
                ></v-text-field>

                <v-checkbox v-model="form.gdpr" required>
                  <template v-slot:label>
                    <div>
                      Ich habe die
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="https://oelsnitz-ticket.de/datenschutz"
                            @click.stop
                            v-on="on"
                          >
                            Datenschutzbestimmungen
                          </a>
                        </template>
                        Datenschutz
                      </v-tooltip>
                      gelesen und stimme zu.
                    </div>
                  </template>
                </v-checkbox>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :disabled="
                    !form.username ||
                    !form.email ||
                    !form.password ||
                    !form.gdpr
                  "
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    bg-gradient-default
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                  "
                  color="#5e72e4"
                  small
                  @click="submitFormRegister"
                  ><span v-if="!updatingAuth">Registrieren</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
                <h5
                  class="
                    text-h5 text-secondary text-center
                    mt-5
                    font-weight-normal
                  "
                >
                  Du hast bereits einen Account?
                </h5>
                <v-row justify="center">
                  <div>
                    <v-btn
                      v-if="!userInformations"
                      :elevation="0"
                      @click="$store.commit('popLogin', true)"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-warning
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                    >
                      Einloggen
                    </v-btn>
                  </div>
                </v-row>
              </div>
              <div v-if="userInformations">
                <h5 class="text-h5 text-secondary font-weight-normal">
                  Wir haben Dir einen Code per E-Mail gesendet, bitte gebe Ihn
                  hier ein:
                </h5>
                <p class="text-danger">
                  {{ authMessage }}
                </p>
                <v-text-field
                  type="number"
                  label="6-Stelliger Code"
                  placeholder="6-Stelliger Code"
                  color="#e91e63"
                  required
                  v-model="form.verificationCode"
                  class="font-size-input input-style mt-10"
                  :rules="min6"
                ></v-text-field>
                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :disabled="form.verificationCode.length != 6"
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    bg-gradient-default
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                  "
                  color="#5e72e4"
                  small
                  @click="verifyEmail"
                  ><span v-if="!updatingAuth">Code bestätigen</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    bg-gradient-primary
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                  "
                  color="#5e72e4"
                  small
                  @click="$store.dispatch('resendCode')"
                  ><span v-if="!updatingAuth"
                    >Neue{{ codeSent ? "r" : "n" }} Code
                    {{ codeSent ? "wurde gesendet" : "senden" }}</span
                  >
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
                <p class="text-center">{{ userInformations.email }}</p>
              </div>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="$store.commit('popRegister', false)"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-secondary btn-outline-secondary
              shadow-none
              bg-transparent
              py-3
              px-6
            "
            >Abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>
<script>
import AppBarPages from "./Widgets/AppBarPages.vue";
import AppBarAuthentication from "./Widgets/AppBarAuthentication.vue";
import AppBarApplications from "./Widgets/AppBarApplications.vue";
import AppBarEcommerce from "./Widgets/AppBarEcommerce.vue";
import AppBarDocs from "./Widgets/AppBarDocs.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  name: "app-bar-auth",
  components: {
    AppBarPages,
    AppBarAuthentication,
    AppBarApplications,
    AppBarEcommerce,
    AppBarDocs,
  },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  computed: {
    accountCreated() {
      return this.$store.getters.accountCreated;
    },
    updatingAuth() {
      return this.$store.getters.updatingAuth;
    },
    authMessage() {
      return this.$store.getters.authMessage;
    },
    popLogin() {
      return this.$store.getters.popLogin;
    },
    popRegister() {
      return this.$store.getters.popRegister;
    },
    userInformations() {
      return this.$store.getters.userInformations;
    },
    accountCompleted() {
      return this.$store.getters.accountCompleted;
    },
    codeSent() {
      return this.$store.getters.codeSent;
    },
    links() {
      var links = [
        {
          name: "Veranstaltungen",
          link: "/events",
          sub: false,
        },
        {
          name: "News",
          link: "/news",
          sub: false,
        },
        {
          name: "So gehts",
          link: "/sogehts",
          sub: false,
        },
      ];

      if (this.userInformations) {
        links.push({
          name: "Mein Bereich",
          sub: true,
        });
        if (this.userInformations.admin) {
        links.push({
          name: "Administration",
          link: "/admin"
        });
      }
      }
      
      return links;
    },
    linksMobile() {
      var links = [
        {
          name: "Veranstaltungen",
          link: "/events",
          sub: false,
        },
        {
          name: "News",
          link: "/news",
          sub: false,
        },
         {
          name: "So gehts",
          link: "/sogehts",
          sub: false,
        },
      ];

      if (this.userInformations) {
        links.push({
          name: "Mein Bereich",
          sub: true,
          links: [
            {
              name: "Profil",
              link: "/profile",
            },
            {
              name: "Tickets",
              link: "/tickets",
            },
            {
              name: "Zahlungen",
              link: "/invoices",
            },
          ],
        });
        if (this.userInformations.admin) {
        links.push({
          name: "Administration",
          sub: true,
          links: [
            {
              name: "Dashboard",
              link: "/admin",
            },
          ],
        });
      }
      }

      

      return links;
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.$store.dispatch("userInformations");
    }
  },
  watch: {
    userInformations() {
      this.loginDialog = false;
      if(!this.userInformations){
        this.$swal("Abgemeldet", 'Du hast dich erfolgreich ausgeloggt, bis bald!', "success");
      }
    },
    popLogin() {
      if (this.popLogin) {
        this.loginDialog = true;
        this.registerDialog = false;
      } else {
        this.loginDialog = false;
      }
    },

    popRegister() {
      if (this.popRegister) {
        this.registerDialog = true;
        this.loginDialog = false;
      } else {
        this.registerDialog = false;
      }
    },

    loginDialog() {
      if (!this.loginDialog) {
        this.$store.commit("popLogin", this.loginDialog);
      }
    },

    registerDialog() {
      if (!this.registerDialog) {
        this.$store.commit("popRegister", this.registerDialog);
      }
    },

    accountCompleted() {
      if (this.accountCompleted) {
        this.registerDialog = false;
      }
    },
  },
  data() {
    return {
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Bitte gebe eine gültige E-Mail-Adresse an",
      ],
      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 Zeichen",
      ],
      min8: [(value) => (value && value.length >= 7) || "Min 8 Zeichen"],
      min6: [
        (value) =>
          (value && value.length > 5 && value.length < 7) || "6 Zeichen",
      ],
      loginDialog: false,
      registerDialog: false,
      dialog: false,
      form: {
        verificationCode: "",
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutModal");
    },
    submitForm() {
      var payload = {
        email: this.form.email,
        password: this.form.password,
      };
      this.$store.dispatch("loginModal", payload);
    },
    submitFormRegister() {
      var payload = {
        email: this.form.email,
        password: this.form.password,
        username: this.form.username,
      };
      this.$store.dispatch("signup", payload);
    },
    verifyEmail() {
      var payload = {
        verificationCode: this.form.verificationCode,
      };
      this.$store.dispatch("verifyEmail", payload);
    },
  },
};
</script>
