<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="
          header-auth
          position-relative
          pb-30
          pt-30
          min-vh-30
          scroll0
          w-100
        "
        v-bind:style="{
          backgroundImage:
            'url(' + require('@/assets/img/markt-oelsnitz.jpeg') + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.4)',
        }"
      >
        <v-container fluid>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0">
              <h2
                class="text-h2 font-weight-bold text-white text-center mb-2"
                v-if="this.$route.name == 'Pricing'"
              >
                {{ headerTitle() }}
              </h2>
              <h1
                class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-2
                  mt-5
                "
                v-else
              >
                {{ headerTitle() }}
              </h1>
              <p
                class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
                {{ paragraphs }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <app-bar-auth
        :login="loginFromSub"
        background="transparent"
        has-bg
        linkColor="white"
      >
      </app-bar-auth>
      <v-container fluid class="pb-0 px-0 mt-5">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view @login="loginFromSub = true"></router-view>Ç
        </fade-transition>
        <div class="mt-15">
          <content-footer></content-footer>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
      loginFromSub: false,
    };
  },
  mounted() {
    console.log("mounted!");
  },
  methods: {
    headerTitle() {
      console.log(this.$route.name);
      switch (this.$route.name) {
        case "Home":
          this.paragraphs =
            "Ihre Buchungsplattform für Veranstaltungen in Oelsnitz/Vogtl. und Umgebung !";
          return "Oelsnitz-Ticket.de";
        case "Events":
          this.paragraphs = "Buche Dein nächstes Event";
          return "Veranstaltungen";
        case "Event Details":
          this.paragraphs = "Buche Dein nächstes Event";
          return "Veranstaltungen";
        case "News":
          this.paragraphs =
            "Hier gibt es aktuelle Informationen rund um Veranstaltungen in der Nähe";
          return "News";
        case "News Details":
          this.paragraphs =
            "Hier gibt es aktuelle Informationen rund um Veranstaltungen in der Nähe";
          return "News";
        case "Event Order":
          this.paragraphs = "Bestelle jetzt Deine Eintrittskarte";
          return "Bestellung";
        case "Register":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Create an account";
        case "Profil":
          this.paragraphs = "Konto Einstellungen";
          return "Mein Profil";
        case "Invoices":
          this.paragraphs = "Überblick Deiner Zahlungen und Rechnungen";
          return "Zahlungen";
        case "Tickets":
          this.paragraphs = "Überblick Deiner Tickets";
          return "Tickets";
        case "Intro":
          this.paragraphs = "So Geht's";
          return "Einführung";
        case "Impressum":
          this.paragraphs = "Rechtliches";
          return "Impressum";
        case "Datenschutz":
          this.paragraphs = "Rechtliches";
          return "Datenschutz";
        default:
          break;
      }
    },
  },
};
</script>
