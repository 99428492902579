<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="
          header-auth
          position-relative
          ma-4
          pb-16
          pt-16
          border-radius-xl
          min-height-300
        "
        v-bind:style="{ backgroundImage: 'url(' + require('@/assets/logo_bp.jpg') + ')', backgroundSize: 'contain', backgroundPosition: 'center' }"
      >
        <span
          class="mask bg-gradient-default border-radius-xl opacity-4"
        ></span>
        <v-img
          src="@/assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          class="position-absolute opacity-6 start-0 top-0 w-100"
          v-if="this.$route.name == 'Pricing'"
        ></v-img>
        <v-container>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0 position-relative">
              <h2
                class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-2
                  mt-5
                "
              >
                
              </h2>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- <app-bar-login background="transparent" has-bg/> -->
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <!-- <content-footer auth v-if="!$route.meta.hideFooter"></content-footer> -->
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>

import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
   
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    authError(){
      return this.$store.getters.authError
    },
    authMessage(){
      return this.$store.getters.authMessage
    },
    darkMode(){
      return this.$store.getters.darkMode
    },
    contactFormSent(){
      return this.$store.getters.contactFormSent
    }
  },
  methods: {
    submitForm(){
      var payload = {
        email: this.form.email,
        password: this.form.password
      }
      this.$store.dispatch('login', payload)
    } 
  },
};
</script>
