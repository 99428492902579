import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthLoginLayout from "../views/Layout/AuthLoginLayout";

// Dashboard pages
const Dashboard = () =>
    import ("../views/Dashboard/Dashboard.vue");
import ("../views/Dashboard/SmartHome.vue");


import ( /* webpackChunkName: "pages" */ "@/views/Pages/Home.vue");
const Profile = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/ProfileOverview.vue");
const Intro = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Intro.vue");
const ProfileOverview = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Pages/Profile/ProfileOverview.vue"
    );

const Login = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/Login/Login.vue"
    );
const Logout = () =>
    import (

        "@/views/Pages/Authentication/Logout/Logout.vue"
    );

const EventsAdmin = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Events/Events.vue");
const Events = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Events.vue");
const NotFound = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/404.vue");
const Home = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Home.vue");
const EventDetailsAdmin = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Events/EventDetails.vue");
const EventDetails = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/EventDetails.vue");
const EventEdit = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Events/EventEdit.vue");
const EventOrder = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/EventOrder.vue");
const NewEvent = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Events/NewEvent.vue");
const NewsAdmin = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/News/News.vue");
const News = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/News.vue");
const NewsDetails = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/NewsDetails.vue");
const Invoices = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Invoices.vue");
const Tickets = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Tickets.vue");
const Impressum = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Impressum.vue");
const Datenschutz = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Datenschutz.vue");
const TicketDetails = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/TicketDetails.vue");
const NewsDetailsAdmin = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/News/NewsDetails.vue");
const NewNews = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/News/NewNews.vue");
const NewsEdit = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/News/NewsEdit.vue");
const Users = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const Quests = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Quests/Quests.vue");
const Feed = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Feed/Feed.vue");
const Reports = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Reports/Reports.vue");
const NewQuest = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Quests/NewQuest.vue");
Vue.use(VueRouter);


let profilePages = {
    path: "/",
    component: ProfileLayout,
    name: "Profile",
    children: [{
        path: "/admin/profile",
        name: "Profilübersicht",
        component: ProfileOverview,
        meta: {
            groupName: "Einstellungen",
        },

    }, ],
};

// let pricingPage = {
//     path: "/",
//     component: PageLayout,
//     // name: "Home Page",
//     children: [{
//             path: "/",
//             name: "Home",
//             component: Home,
//         },
//         {
//             path: "/events",
//             name: "Events",
//             component: Events,
//         },
//         {
//             path: "/events/:id",
//             name: "Event Details",
//             component: EventDetails,
//         },
//         {
//             path: "/events/order/:id",
//             name: "Event Order",
//             component: EventOrder,
//         },
//         {
//             path: "/news",
//             name: "News",
//             component: News,
//         },
//         {
//             path: "/news/:id",
//             name: "News Details",
//             component: NewsDetails,
//         },
//         {
//             path: "/profile",
//             name: "Profil",
//             component: Profile,
//         },
//         {
//             path: "/sogehts",
//             name: "Intro",
//             component: Intro,
//         },
//         {
//             path: "/invoices",
//             name: "Invoices",
//             component: Invoices,
//         },
//         {
//             path: "/tickets",
//             name: "Tickets",
//             component: Tickets,
//         },
//         {
//             path: "/impressum",
//             name: "Impressum",
//             component: Impressum,
//         },
//         {
//             path: "/datenschutz",
//             name: "Datenschutz",
//             component: Datenschutz,
//         },
//         {
//             path: "/tickets/:id",
//             name: "Tickets",
//             component: TicketDetails,
//         },
//     ],
// };


let loginPages = {
    path: "/",
    component: AuthLoginLayout,
    name: "Login",
    children: [{
        path: "/admin/login",
        name: "Login",
        component: Login,
    }, {
        path: "/logout",
        name: "Logout",
        component: Logout,
    }, ],
};

let notFoundPage = {
    path: "*",
    component: DashboardLayout,
    name: "NotFound",
    children: [{
        path: "/",
        name: "404",
        component: NotFound,
    }],
};

// let eventPages = {
//     path: "/",
//     component: DashboardLayout,
//     name: "Events",
//     children: [{
//         path: "/admin/events",
//         name: "Events",
//         component: EventsAdmin,
//         meta: {
//             groupName: "Events",
//         },
//     }, {
//         path: "/admin/events/details/:id",
//         name: "Event Details",
//         component: EventDetailsAdmin,
//         meta: {
//             groupName: "Events",
//         },
//     }, {
//         path: "/admin/events/edit/:id",
//         name: "Event bearbeiten",
//         component: EventEdit,
//         meta: {
//             groupName: "Events",
//         },
//     }, {
//         path: "/admin/events/new/",
//         name: "Event anlegen",
//         component: NewEvent,
//         meta: {
//             groupName: "Events",
//         },
//     }, ],
// };

let userPages = {
    path: "/",
    component: DashboardLayout,
    name: "Users",
    children: [{
        path: "/admin/users",
        name: "Overview",
        component: Users,
        meta: {
            groupName: "Users",
        },
    }],
};

let questsPages = {
    path: "/",
    component: DashboardLayout,
    name: "Quests",
    children: [{
        path: "/admin/quests",
        name: "Overview",
        component: Quests,
        meta: {
            groupName: "Quests",
        },
    }, {
        path: "/admin/quests/new/",
        name: "Quest anlegen",
        component: NewQuest,
        meta: {
            groupName: "Quest",
        },
    }, ],
};


let feedPages = {
    path: "/",
    component: DashboardLayout,
    name: "Feed",
    children: [{
        path: "/admin/feed",
        name: "Overview",
        component: Feed,
        meta: {
            groupName: "Feed",
        },
    }],
};

let reportPages = {
    path: "/",
    component: DashboardLayout,
    name: "Reports",
    children: [{
        path: "/admin/reports",
        name: "Overview",
        component: Reports,
        meta: {
            groupName: "Reports",
        },
    }],
};

// let newsPages = {
//     path: "/",
//     component: DashboardLayout,
//     name: "News",
//     children: [{
//             path: "/admin/news",
//             name: "News",
//             component: NewsAdmin,
//             meta: {
//                 groupName: "News",
//             },
//         }, {
//             path: "/admin/news/details/:id",
//             name: "News Details",
//             component: NewsDetailsAdmin,
//             meta: {
//                 groupName: "News",
//             },
//         },
//         {
//             path: "/admin/news/new/",
//             name: "News anlegen",
//             component: NewNews,
//             meta: {
//                 groupName: "News",
//             },
//         },
//         {
//             path: "/admin/news/edit/:id",
//             name: "News bearbeiten",
//             component: NewsEdit,
//             meta: {
//                 groupName: "News",
//             },
//         },
//     ],
// };


const routes = [{
        path: "/admin/",
        name: "Dashboard",
        redirect: "/admin/analytics",
        component: DashboardLayout,
        children: [{
                path: "/admin/analytics",
                name: "Analytics",
                component: Dashboard,
                meta: {
                    groupName: "Dashboards",
                },
            },

        ],
    },
    reportPages,
    feedPages,
    questsPages,
    // pricingPage,
    userPages,
    profilePages,
    loginPages,
    // eventPages,
    // newsPages,
    notFoundPage
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;